import { ReactNode, forwardRef } from "react";
import { NumericFormatProps } from "react-number-format";
import { NumberInput } from "~/components/v2/NumberInput";
import DOMPurify from "dompurify";

interface NumberFieldProps extends NumericFormatProps {
  label: string;
  error?: string;
  startAdornment?: ReactNode;
}

const hasHTMLContent = (label: string) => {
  const htmlRegex = /<([a-z]+)([^<]+)*(?:>(.*)<\/\1>|\s+\/>)/i;
  return htmlRegex.test(label);
}

const getLabelValue = (label: string, required?: boolean) => {
  if (hasHTMLContent(label)) {
    return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }} />;
  } 
  return (
      <span className="text-sm font-semibold">
        {label}
        {required && "*"}
      </span>
    );
};

export const NumberField = forwardRef<HTMLInputElement, NumberFieldProps>(
  ({ label, error, startAdornment, required, ...props }, ref) => {
    return (
      <div className="flex flex-col gap-1">
        {label && (
          getLabelValue(label, required)
        )}
        <div className="relative mt-1">
          <NumberInput
            ref={ref}
            placeholder="Example"
            className={`peer h-[2.625rem] border-[#595959] py-2 placeholder:text-[#999999] placeholder-shown:border-[#ccc] ${
              error &&
              "border-error bg-[#FFF5F5] text-error placeholder-shown:border-error"
            } ${startAdornment && "pl-7"}`}
            {...props}
          />
          <span
            className={`absolute left-0 top-0 flex h-full items-center px-3 peer-placeholder-shown:text-[#999] ${
              error && "text-error"
            }`}
          >
            {startAdornment}
          </span>
        </div>
        {error && (
          <span className="flex items-center gap-1 text-xs text-error">
            <i className="mi-circle-error"></i>
            {error}
          </span>
        )}
      </div>
    );
  },
);

NumberField.displayName = "NumberField";
