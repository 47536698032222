import { forwardRef } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { Combobox } from "~/components/v2/Combobox";
import { FormField } from "~/components/v2/Form";
import { cn } from "~/utils/cn";

export interface SelectFieldProps {
  control: Control<any>;
  collisionPadding?: number;
  label: string;
  error?: string;
  name: string;
  placeholder?: string;
  options: { label: string; value: string }[];
  setValue: UseFormSetValue<any>;
  searchByLabel?: boolean;
  disabled?: boolean;
  className?: string;
  required?: boolean;
}

export const SelectField = forwardRef<HTMLButtonElement, SelectFieldProps>(
  (
    {
      label,
      error,
      control,
      collisionPadding,
      name,
      options,
      setValue,
      placeholder = "Example",
      searchByLabel,
      disabled,
      required,
      className = "",
    },
    ref,
  ) => {
    return (
      <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <div className={`flex flex-col gap-1 ${disabled && "opacity-50"}`}>
            {label && (
              <span className="text-sm font-semibold">
                {label}
                {required && "*"}
              </span>
            )}
            <div className={cn("flex flex-col", className)}>
              <Combobox
                collisionPadding={collisionPadding}
                searchByLabel={searchByLabel}
                multi={false}
                placeholder={placeholder}
                options={options}
                value={`${field.value}`}
                setValue={(field, value) =>
                  setValue(field, value, { shouldDirty: true })
                }
                name={field.name}
                emptyTerm={label.toLowerCase()}
                searchPlaceholder="Search..."
                disabled={disabled}
                className={`h-[2.625rem] py-2 ${
                  field.value || field.value === 0
                    ? "border-[#595959]"
                    : "border-[#ccc] text-[#999999]"
                } ${
                  error &&
                  "border-error bg-[#FFF5F5] text-error placeholder-shown:border-error"
                }`}
                icon={<i className="mi-chevron-down h-4 w-4" />}
                onBlur={field.onBlur}
                ref={ref}
                aria-label={label}
              />
            </div>
            {error && (
              <span className="flex items-center gap-1 text-xs text-error">
                <i className="mi-circle-error"></i>
                {error}
              </span>
            )}
          </div>
        )}
      />
    );
  },
);

SelectField.displayName = "SelectField";
