import {
  FocusEventHandler,
  FormEventHandler,
  KeyboardEventHandler,
  forwardRef,
} from "react";
import { cn } from "~/utils/cn";
import { Badge } from "../Badge";

export interface TagsInputProps {
  className?: string;
  values: string[];
  onChange: (values: string[]) => void;
  placeholder?: string;
  inputClassName?: string;
}

const TagsInput = forwardRef<HTMLInputElement, TagsInputProps>(
  ({ className, placeholder, values, onChange, inputClassName }, ref) => {
    const handleAdd = (value: string) => {
      onChange([...values, value]);
    };

    const handleRemove = (index: number) => () => {
      const copy = [...values];
      copy.splice(index, 1);
      onChange(copy);
    };

    const handleKeyPressed: KeyboardEventHandler = (e) => {
      if (e.key !== "," && e.key !== "Enter" && e.key !== "Backspace") return;
      const input = e.target as HTMLInputElement;
      if (e.key === "," || e.key === "Enter") {
        e.preventDefault();
        if (input.value.length === 0) return;
        handleAdd(input.value);
        input.value = "";
        return;
      }
      if (input.value.length > 0) return;
      handleRemove(values.length - 1)();
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
      const input = e.target;
      if (!input.value.length) return;

      handleAdd(input.value);
      input.value = "";
    };

    return (
      <div
        className={cn(
          "min-h-9 border-input bg-background flex w-full flex-wrap gap-2 rounded-md border px-3 py-2 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium",
          className,
        )}
      >
        {values.map((value, i) => (
          <Badge key={i} className="gap-1 rounded-full bg-[#003D94] px-2">
            {value}
            <i className="mi-close" role="button" onClick={handleRemove(i)}></i>
          </Badge>
        ))}
        <input
          className={cn(
            "placeholder:text-muted-foreground flex min-w-[5rem] flex-1 focus:outline-none",
            inputClassName,
          )}
          placeholder={placeholder}
          onKeyDown={handleKeyPressed}
          onBlur={handleBlur}
          ref={ref}
        />
      </div>
    );
  },
);

TagsInput.displayName = "TagsInput";

export { TagsInput };
