import { InputHTMLAttributes, forwardRef } from "react";
import { Textarea } from "~/components/v2/Textarea";

interface TextAreaFieldProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  error?: string;
  subText?: string;
  onValueChange?: (name: string, value: string) => void;
}

export const TextAreaField = forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldProps
>(({ label, error, subText, required, ...props }, ref) => {
  return (
    <div className="flex flex-col gap-1">
      {label && (
        <span className="text-sm font-semibold">
          {label}
          {required && "*"}
        </span>
      )}
      <span className="text-sm text-[#737373]">{subText}</span>
      <div
        className={`overflow-hidden rounded-md border border-[#595959] [&:has(textarea:placeholder-shown)]:border-[#ccc] ${error &&
          "border-error [&:has(textarea:placeholder-shown)]:border-error"
          }`}
      >
        <Textarea
          placeholder="Example"
          className={`resize-none border-none py-2 placeholder:text-[#999999] ${error && "bg-[#FFF5F5] text-error"
            }`}
          rows={9}
          {...props}
          ref={ref}
          aria-label={props.placeholder}
        />
      </div>
      {error && (
        <span className="flex items-center gap-1 text-xs text-error">
          <i className="mi-circle-error"></i>
          {error}
        </span>
      )}
    </div>
  );
});

TextAreaField.displayName = "TextAreaField";
