"use client";

import {
  FC,
  FormEventHandler,
  KeyboardEventHandler,
  PropsWithChildren,
  ReactNode,
  useState,
} from "react";
import { Button } from "~/components/v2/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "~/components/v2/Dialog";
import ConfirmDialog from "../Dialog";
import { UseFormReset } from "react-hook-form";
import { TextField } from "./TextField";
import { TextAreaField } from "./TextAreaField";
import { TagField } from "./TagField";
import { SelectField } from "./SelectField";
import { RadioField } from "./RadioField";
import { NumberField } from "./NumberField";
import { CheckboxField } from "./CheckboxField";
import Spinner from "~/components/spinner";
import Edit from "~/icons/Edit";

interface EditFormProps {
  iconTrigger?: ReactNode;
  title: string;
  subTitle: string;
  onSubmit?: (data: any) => Promise<void>;
  submitHandler?: (callback: (data: any) => void) => FormEventHandler;
  isDirty?: boolean;
  reset?: UseFormReset<any>;
  onDelete?: () => void;
  isDeleting?: boolean;
  isValid?: boolean;
  hideFooter?: boolean;
  fullSizeTrigger?: boolean;
  needsValidity?: boolean;
}

const EditForm: FC<PropsWithChildren<EditFormProps>> = ({
  iconTrigger = (
    <span className="inline-block p-1">
      <Edit />
    </span>
  ),
  title,
  subTitle,
  children,
  onSubmit,
  submitHandler,
  isDirty,
  reset,
  onDelete,
  isDeleting,
  isValid,
  hideFooter,
  fullSizeTrigger,
  needsValidity,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const closeConfirmDialog = () => setConfirmDialogOpen(false);
  const handleConfirm = () => {
    setOpen(false);
    closeConfirmDialog();
    if (reset) reset();
  };
  const openDialog = () => setOpen(true);
  const closeDialog = () => {
    if (isDirty) {
      setConfirmDialogOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleSubmit: FormEventHandler = e => {
    if (!submitHandler || !onSubmit) return;
    return submitHandler(data => {
      setLoading(true);
      onSubmit(data)
        .then(() => {
          setOpen(false);
        })
        .finally(() => {
          setLoading(false);
        });
    })(e);
  };

  const handleKeyDown: KeyboardEventHandler = e => {
    if (e.key === "Escape") closeDialog();
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTrigger onClick={openDialog}>
          <div
            className={`flex items-center justify-center rounded-full hover:bg-[#E5F2FF] active:bg-[#D8EBFF] ${
              fullSizeTrigger ? "" : "h-8 w-8"
            }`}
          >
            {iconTrigger}
          </div>
        </DialogTrigger>
        <DialogContent
          hideClose
          overlayClassName="bg-[#000D3D26]"
          className="w-screen max-w-none overflow-hidden p-0 xs:max-lg:left-0 xs:max-lg:top-0 xs:max-lg:translate-x-0 xs:max-lg:translate-y-0 lg:max-w-[37rem] lg:rounded-xl"
          onPointerDownOutside={closeDialog}
          onKeyDown={handleKeyDown}
        >
          <form
            className="grid h-[100dvh] w-full grid-rows-[3.5rem_1fr_4.5rem] bg-white lg:max-h-[32rem] xl:max-h-[42rem]"
            onSubmit={handleSubmit}
          >
            <DialogHeader className="z-20 h-fit bg-white py-2">
              <div className="relative flex items-center justify-center">
                <Button
                  variant="ghost"
                  size="icon"
                  className="absolute left-4"
                  type="button"
                  onClick={closeDialog}
                >
                  <i className="mi-close text-2xl"></i>
                </Button>
                <div className="flex flex-col text-center">
                  <span className="text-2xl font-semibold text-[#000D3D]">
                    {title}
                  </span>
                  <span className="text-xs text-[#595959]">{subTitle}</span>
                </div>
              </div>
            </DialogHeader>
            <div className="z-10 overflow-auto pt-[10px]">
              <div className="mx-auto flex w-full max-w-lg flex-col gap-4 px-5 pb-4 pt-2 text-[#000D3D]">
                {children}
              </div>
            </div>
            {!hideFooter && (
              <DialogFooter className="flex flex-row-reverse justify-between px-5 py-4 sm:flex-row-reverse sm:justify-between">
                <Button
                  type="submit"
                  className="h-full w-[7.5rem] rounded-xl"
                  disabled={
                    loading || (needsValidity && (!isValid || !isDirty))
                  }
                >
                  {loading ? <Spinner /> : "Save"}
                </Button>
                {!!onDelete && (
                  <ConfirmDialog
                    trigger={
                      <Button
                        variant="ghost"
                        type="button"
                        className="hover:bg-color-[none] active:bg-color-[none] h-auto  rounded-xl p-0 text-[#B41B30] hover:underline active:text-[#66001E]"
                      >
                        Delete
                      </Button>
                    }
                    title={`Delete ${title.toLowerCase()}`}
                    description={`This will delete your ${title.toLowerCase()} from your profile. Are you sure?`}
                    onConfirm={onDelete}
                    loading={isDeleting}
                  />
                )}
              </DialogFooter>
            )}
          </form>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialog}
        onConfirm={handleConfirm}
        title="Discard changes"
        description="Are you sure you want to discard the changes you made?"
        cancelText="No, thanks"
        confirmText="Discard"
      />
    </>
  );
};

interface SectionProps {
  title: string;
}

const Section: FC<PropsWithChildren<SectionProps>> = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-2">
      <span className="text-lg font-semibold">{title}</span>
      {children}
    </div>
  );
};

export {
  EditForm,
  Section,
  TextField,
  TextAreaField,
  TagField,
  SelectField,
  RadioField,
  NumberField,
  CheckboxField,
};
