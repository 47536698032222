import * as React from "react";

import { cn } from "~/utils/cn";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onValueChange?: (name: string, value: string) => void;
  error?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, onVolumeChange, name, onValueChange, error, ...props }, ref) => {
    return (
      <><textarea
        name={name}
        className={cn(
          "flex min-h-[60px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        onChange={(e) => {
          onValueChange?.(name!, e.target.value)
        }}
        {...props}
        aria-label={props.placeholder}
      />
        {error && (
          <span className="flex items-center gap-1 text-xs text-error">
            <i className="mi-circle-error"></i>
            {error}
          </span>
        )}
      </>
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
