import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { cn } from "~/utils/cn";

import { FaCheck } from "react-icons/fa6";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "focus-visible:ring-ring peer h-4 w-4 shrink-0 rounded border  border-neutral-rest p-1 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-0  data-[state=checked]:bg-neutral-primary data-[state=checked]:text-neutral-on-brand",
      className,
    )}
    {...props}
    aria-label={props["aria-label"]}
  >
    <CheckboxPrimitive.Indicator
      className={cn(
        "text-neutral-00 flex h-full w-full items-center  justify-center",
      )}
    >
      <FaCheck className="h-3 w-3" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
