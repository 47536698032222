export type optionsType = {
  label: string;
  value: any;
};

export type optionGrp = {
  label: string;
  value?: any;
  options: optionsType[];
};

export type industryValues =
  | "AEROSPACE_AND_DEFENSE"
  | "AGRICULTURE"
  | "ARTS_ENTERTAINMENT_AND_RECREATION"
  | "AUTOMOTIVE"
  | "CONSTRUCTION"
  | "CONSULTING_SERVICES"
  | "EDUCATION"
  | "E_COMMERCE"
  | "ENERGY_MINING_AND_UTILITIES"
  | "ENVIRONMENTAL_SERVICES"
  | "FASHION_AND_APPAREL"
  | "FINANCIAL_SERVICES"
  | "FOOD_AND_BEVERAGE"
  | "GOVERNMENT_AND_PUBLIC_ADMINSTRATION"
  | "HEALTHCARE"
  | "HOTELS_AND_TRAVEL_ACCOMODATION"
  | "HUMAN_RESOURCES_AND_STAFFING"
  | "INFORMATION_TECHNOLOGY"
  | "INSURANCE"
  | "LEGAL"
  | "MANAGEMENT_AND_CONSULTING"
  | "MANUFACTURING"
  | "MEDIA_AND_COMMUNICATION"
  | "NONPROFIT_AND_NGO"
  | "PERSONAL_CONSUMER_SERVICES"
  | "PHARMACEUTICAL_AND_BIOTECH"
  | "PROFESSIONAL_SERVICES"
  | "REAL_ESTATE"
  | "RESTAURANTS_AND_FOOD_SERVICE"
  | "RETAIL_AND_WHOLESALE"
  | "SOCIAL_MEDIA_AND_NETWORKING"
  | "TELECOMMUNICATIONS"
  | "TRANSPORTATION_AND_LOGISTICS";

export const industries: optionsType[] = [
  {
    label: "Aerospace & Defense",
    value: "AEROSPACE_AND_DEFENSE",
  },
  {
    label: "Agriculture",
    value: "AGRICULTURE",
  },
  {
    label: "Arts, Entertainment & Recreation",
    value: "ARTS_ENTERTAINMENT_AND_RECREATION",
  },
  { label: "Automotive", value: "AUTOMOTIVE" },

  {
    label: "Construction & Facilities Services",
    value: "CONSTRUCTION",
  },
  {
    label: "Consulting Services",
    value: "CONSULTING_SERVICES",
  },
  {
    label: "Education",
    value: "EDUCATION",
  },
  {
    label: "Energy, Mining & Utilities",
    value: "ENERGY_MINING_AND_UTILITIES",
  },
  { label: "E-commerce", value: "E_COMMERCE" },
  {
    label: "Environmental Services",
    value: "ENVIRONMENTAL_SERVICES",
  },
  {
    label: "Fashion and Apparel",
    value: "FASHION_AND_APPAREL",
  },
  {
    label: "Financial Services",
    value: "FINANCIAL_SERVICES",
  },
  {
    label: "Food and Beverage",
    value: "FOOD_AND_BEVERAGE",
  },
  {
    label: "Government & Public Administration",
    value: "GOVERNMENT_AND_PUBLIC_ADMINSTRATION",
  },
  {
    label: "Healthcare",
    value: "HEALTHCARE",
  },
  {
    label: "Hotels & Travel Accommodation",
    value: "HOTELS_AND_TRAVEL_ACCOMODATION",
  },
  {
    label: "Human Resources & Staffing",
    value: "HUMAN_RESOURCES_AND_STAFFING",
  },
  {
    label: "Information Technology",
    value: "INFORMATION_TECHNOLOGY",
  },
  {
    label: "Insurance",
    value: "INSURANCE",
  },
  {
    label: "Legal",
    value: "LEGAL",
  },
  {
    label: "Management & Consulting",
    value: "MANAGEMENT_AND_CONSULTING",
  },
  {
    label: "Manufacturing",
    value: "MANUFACTURING",
  },
  {
    label: "Media & Communication",
    value: "MEDIA_AND_COMMUNICATION",
  },
  {
    label: "Nonprofit & NGO",
    value: "NONPROFIT_AND_NGO",
  },
  {
    label: "Personal Consumer Services",
    value: "PERSONAL_CONSUMER_SERVICES",
  },
  {
    label: "Pharmaceutical and Biotechnology",
    value: "PHARMACEUTICAL_AND_BIOTECH",
  },
  {
    label: "Professional Services",
    value: "PROFESSIONAL_SERVICES",
  },
  {
    label: "Real Estate",
    value: "REAL_ESTATE",
  },
  {
    label: "Restaurants & Food Service",
    value: "RESTAURANTS_AND_FOOD_SERVICE",
  },
  {
    label: "Retail & Wholesale",
    value: "RETAIL_AND_WHOLESALE",
  },
  {
    label: "Social Media and Networking",
    value: "SOCIAL_MEDIA_AND_NETWORKING",
  },

  {
    label: "Telecommunications",
    value: "TELECOMMUNICATIONS",
  },
  {
    label: "Transportation & Logistics",
    value: "TRANSPORTATION_AND_LOGISTICS",
  },
];

export const subIndustries: { [key in industryValues]: optionsType[] } = {
  AEROSPACE_AND_DEFENSE: [
    { label: "Aviation Services", value: "AVIATION_SERVICES" },
  ],
  AGRICULTURE: [
    { label: "Animal Production", value: "ANIMAL_PRODUCTION" },
    { label: "Crop Production", value: "CROP_PRODUCTION" },
    { label: "Farm Support", value: "FARM_SUPPORT" },
    { label: "Fishery", value: "FISHERY" },
    { label: "Floral Nursery", value: "FLORAL_NURSERY" },
    {
      label: "Forestry, Logging & Timber Operations",
      value: "FORESTRY_LOGGING_AND_TIMBER_OPERATIONS",
    },
  ],
  ARTS_ENTERTAINMENT_AND_RECREATION: [
    { label: "Art Galleries", value: "ART_GALLERIES" },
    { label: "Culture & Entertainment", value: "CULTURE_AND_ENTERTAINMENT" },
    { label: "Film Production", value: "FILM_PRODUCTION" },
    { label: "Gambling", value: "GAMBLING" },
    { label: "Photography", value: "PHOTOGRAPHY" },
    { label: "Publishing", value: "PUBLISHING" },
    { label: "Music & Sound Production", value: "MUSIC_AND_SOUND_PRODUCTION" },
    { label: "Sports & Recreation", value: "SPORT_AND_RECREATION" },
    { label: "Ticket Sales", value: "TICKET_SALES" },
    {
      label: "Translation & Linguistic Services",
      value: "TRANSLATION_AND_LINGUISTIC_SERVICES",
    },
    { label: "Video Game Publishing", value: "VIDEO_GAME_PUBLISHING" },
  ],
  AUTOMOTIVE: [
    { label: "Vehicle Dealers", value: "VEHICLE_DEALERS" },
    { label: "Vehicle Manufacturing", value: "VEHICLE_MANUFACTURING" },
    {
      label: "Vehicle Repair & Maintenance",
      value: "VEHICLE_REPAIR_AND_MAINTENANCE",
    },
    {
      label: "Electric Vehicle Manufacturing",
      value: "ELECTRIC_VEHICLE_MANUFACTURING",
    },
  ],
  CONSTRUCTION: [
    {
      label: "Architectural & Engineering Services",
      value: "ARCHITECTURAL_AND_ENGINEERING_SERVICES",
    },
    {
      label: "Commercial Equipment Services",
      value: "COMMERCIAL_EQUIPMENT_SERVICES",
    },
    {
      label: "Construction, Repair & Facilities Services",
      value: "CONSTRUCTION_REPAIR_AND_FACILITIES_SERVICES",
    },
  ],
  CONSULTING_SERVICES: [
    { label: "CFO Consulting", value: "CFO_CONSULTING" },
    {
      label: "Human Resources Consulting",
      value: "HUMAN_RESOURCES_CONSULTING",
    },
    { label: "Marketing Consulting", value: "MARKETING_CONSULTING" },
    { label: "Sales Consulting", value: "SALES_CONSULTING" },
    { label: "Strategy Consulting", value: "STRATEGY_CONSULTING" },
  ],
  EDUCATION: [
    { label: "Colleges & Universities", value: "COLLEGES_AND_UNIVERSITIES" },
    {
      label: "Education & Training Services",
      value: "EDUCATION_AND_TRAINING_SERVICES",
    },
    { label: "Educational Software", value: "EDUCATIONAL_SOFTWARE" },
    {
      label: "Learning Management Systems",
      value: "LEARNING_MANAGEMENT_SYSTEMS",
    },
    { label: "Online Learning Platforms", value: "ONLINE_LEARNING_PLATFORMS" },
    {
      label: "Preschools & Childcare Services",
      value: "PRESCHOOLS_AND_CHILDCARE_SERVICES",
    },
    {
      label: "Primary & Secondary Schools",
      value: "PRIMARY_AND_SECONDARY_SCHOOLS",
    },
  ],
  E_COMMERCE: [
    {
      label: "Logistics and Fulfillment Services",
      value: "LOGISTICS_AND_FULFILLMENT_SERVICES",
    },
    { label: "Online Marketplace", value: "ONLINE_MARKETPLACE" },
    { label: "Online Payment Solutions", value: "ONLINE_PAYMENT_SOLUTIONS" },
  ],
  ENERGY_MINING_AND_UTILITIES: [
    { label: "Energy & Utilities", value: "ENERGY_AND_UTILITIES" },
    { label: "Mining & Metals", value: "MINING_AND_METALS" },
    { label: "Renewable Energy", value: "RENEWABLE_ENERGY" },
  ],
  ENVIRONMENTAL_SERVICES: [
    { label: "Environmental Consulting", value: "ENVIRONMENTAL_CONSULTING" },
    { label: "Renewable Energy", value: "RENEWABLE_ENERGY" },
    { label: "Waste Management", value: "WASTE_MANAGEMENT" },
  ],
  FASHION_AND_APPAREL: [
    { label: "Clothing Retailers", value: "CLOTHING_RETAILERS" },
    { label: "Fashion Designers", value: "FASHION_DESIGNERS" },
    {
      label: "Textile and Fabric Manufacturing",
      value: "TEXTILE_AND_FABRIC_MANUFACTURING",
    },
  ],
  FINANCIAL_SERVICES: [
    { label: "Accounting & Tax", value: "ACCOUNTING_AND_TAX" },
    { label: "Banking & Lending", value: "BANKING_AND_LENDING" },
    { label: "Debt Relief", value: "DEBT_RELIEF" },
    { label: "Digital Payments", value: "DIGITAL_PAYMENTS" },

    {
      label: "Financial Planning and Advisory",
      value: "FINANCIAL_PLANNING_AND_ADVISORY",
    },
    {
      label: "Financial Transaction Processing",
      value: "FINANCIAL_TRANSACTION_PROCESSING",
    },
    {
      label: "Investment & Asset Management",
      value: "INVESTMENT_AND_ASSET_MANAGEMENT",
    },
    { label: "Peer-to-Peer Lending", value: "PEER_TO_PEER_LENDING" },
    { label: "Robo-Advisory", value: "ROBO_ADVISORY" },
    { label: "Stock Exchanges", value: "STOCK_EXCHANGES" },
  ],
  FOOD_AND_BEVERAGE: [
    { label: "Beverage Production", value: "BEVERAGE_PRODUCTION" },
    { label: "Food Delivery Services", value: "FOOD_DELIVERY_SERVICES" },
    { label: "Organic and Natural Foods", value: "ORGANIC_AND_NATURAL_FOODS" },
  ],
  GOVERNMENT_AND_PUBLIC_ADMINSTRATION: [
    { label: "Municipal Agencies", value: "MUNICIPAL_AGENCIES" },
    { label: "National Agencies", value: "NATIONAL_AGENCIES" },
    {
      label: "State & Regional Agencies",
      value: "STATE_AND_REGIONAL_AGENCIES",
    },
  ],
  HEALTHCARE: [
    {
      label: "Ambulance & Medical Transportation",
      value: "AMBULANCE_AND_MEDICAL_TRANSPORTATION",
    },
    { label: "Dental Clinics", value: "DENTAL_CLINICS" },
    { label: "Healthcare Technology", value: "HEALTHCARE_TECHNOLOGY" },
    { label: "Home Healthcare", value: "HOME_HEALTHCARE" },
    {
      label: "Hospitals & Health Clinics",
      value: "HOSPITALS_AND_HEALTH_CLINICS",
    },
    {
      label: "Medical Equipment and Supplies",
      value: "MEDICAL_EQUIPMENT_AND_SUPPLIES",
    },
    {
      label: "Medical Testing & Clinical Laboratories",
      value: "MEDICAL_TESTING_AND_CLINICAL_LABORATORIES",
    },
    { label: "Nursing Care Facilities", value: "NURSING_CARE_FACILITIES" },
    { label: "Rehabilitation Services", value: "REHABILITATION_SERVICES" },
  ],
  HOTELS_AND_TRAVEL_ACCOMODATION: [
    { label: "Cruise Lines", value: "CRUISE_LINES" },
    { label: "Event Management", value: "EVENT_MANAGEMENT" },
    { label: "Hotels & Resorts", value: "HOTELS_AND_RESORTS" },
    { label: "Theme Parks", value: "THEME_PARKS" },
    { label: "Travel Agencies", value: "TRAVEL_AGENCIES" },
  ],
  HUMAN_RESOURCES_AND_STAFFING: [
    { label: "HR Consulting", value: "HR_CONSULTING" },
    {
      label: "Staffing & Subcontracting",
      value: "STAFFING_AND_SUBCONTRACTING",
    },
  ],
  INFORMATION_TECHNOLOGY: [
    { label: "Cloud Computing", value: "CLOUD_COMPUTING" },
    {
      label: "Computer Hardware Development",
      value: "COMPUTER_HARDWARE_DEVELOPMENT",
    },
    { label: "Cybersecurity", value: "CYBERSECURITY" },
    { label: "Data and Analytics", value: "DATA_AND_ANALYTICS" },
    {
      label: "Information Technology Support Services",
      value: "INFORMATION_TECHNOLOGY_SUPPORT_SERVICES",
    },
    { label: "Internet & Web Services", value: "INTERNET_AND_WEB_SERVICES" },
    {
      label: "Software as a Service",
      value: "SOFTWARE_AS_A_SERVICE",
    },
    { label: "Software Development", value: "SOFTWARE_DEVELOPMENT" },
    {
      label: "Telecommunications Equipment",
      value: "TELECOMMUNICATIONS_EQUIPMENT",
    },
  ],
  INSURANCE: [
    { label: "Health Insurance Carriers", value: "HEALTH_INSURANCE_CARRIERS" },
    {
      label: "Insurance Agencies & Brokerages",
      value: "INSURANCE_AGENCIES_AND_BROKERAGES",
    },
    { label: "Insurance Carriers", value: "INSURANCE_CARRIERS" },
  ],
  LEGAL: [
    { label: "Law Firms", value: "LAW_FIRMS" },
    { label: "Legal Services", value: "LEGAL_SERVICES" },
  ],
  MANAGEMENT_AND_CONSULTING: [
    { label: "Business Consulting", value: "BUSINESS_CONSULTING" },
    { label: "Membership Organizations", value: "MEMBERSHIP_ORGANIZATIONS" },
    { label: "Research & Development", value: "RESEARCH_AND_DEVELOPMENT" },
    { label: "Security & Protective", value: "SECURITY_AND_PROTECTIVE" },
  ],
  MANUFACTURING: [
    { label: "Chemical Manufacturing", value: "CHEMICAL_MANUFACTURING" },
    { label: "Commercial Printing", value: "COMMERCIAL_PRINTING" },
    {
      label: "Consumer Product Manufacturing",
      value: "CONSUMER_PRODUCT_MANUFACTURING",
    },
    { label: "Electronics Manufacturing", value: "ELECTRONICES_MANUFACTURING" },
    {
      label: "Food & Beverage Manufacturing",
      value: "FOOD_AND_BEVERAGE_MANUFACTURING",
    },
    {
      label: "Health Care Products Manufacturing",
      value: "HEALTH_CARE_PRODUCTS_MANUFACTURING",
    },
    { label: "Machinery Manufacturing", value: "MACHINERY_MANUFACTURING" },
    {
      label: "Metal & Mineral Manufacturing",
      value: "METAL_AND_MINERAL_MANUFACTURING",
    },
    {
      label: "Textile & Apparel Manufacturing",
      value: "TEXTILE_AND_APPAREL_MANUFACTURING",
    },
    {
      label: "Transportation Equipment Manufacturing",
      value: "TRANSPORTATION_EQUIPMENT_MANUFACTURING",
    },
    {
      label: "Wood & Paper Manufacturing",
      value: "WOOD_AND_PAPER_MANUFACTURING",
    },
  ],
  MEDIA_AND_COMMUNICATION: [
    {
      label: "Advertising & Public Relations",
      value: "ADVERTISING_AND_PUBLIC_RELATIONS",
    },
    { label: "Broadcast Media", value: "BROADCAST_MEDIA" },
    { label: "Film Production", value: "FILM_PRODUCTION" },
    { label: "Music & Sound Production", value: "MUSIC_AND_SOUND_PRODUCTION" },
    { label: "Photography", value: "PHOTOGRAPHY" },
    { label: "Publishing", value: "PUBLISHING" },
    {
      label: "Translation & Linguistic Services",
      value: "TRANSLATION_AND_LINGUISTIC_SERVICES",
    },
    { label: "Video Game Publishing", value: "VIDEO_GAME_PUBLISHING" },
  ],
  NONPROFIT_AND_NGO: [
    { label: "Civic & Social Services", value: "CIVIC_AND_SOCIAL_SERVICES" },
    {
      label: "Grantmaking & Charitable Foundations",
      value: "GRANTMAKING_AND_CHARITABLE_FOUNDATIONS",
    },
    { label: "Religious Institutions", value: "RELIGIOUS_INSTITUTIONS" },
  ],
  PERSONAL_CONSUMER_SERVICES: [
    { label: "Beauty & Wellness", value: "BEAUTY_AND_WELLNESS" },
    { label: "Event Services", value: "EVENT_SERVICES" },
    { label: "Laundry & Dry Cleaning", value: "LAUNDRY_AND_DRY_CLEANING" },
    { label: "Pet Care & Veterinary", value: "PET_CARE_AND_VETERINARY" },
    { label: "Private Households", value: "PRIVATE_HOUSEHOLDS" },
  ],
  PHARMACEUTICAL_AND_BIOTECH: [
    { label: "Biotechnology", value: "BIOTECHNOLOGY" },
    { label: "Pharmaceutical", value: "PHARMACEUTICAL" },
  ],
  PROFESSIONAL_SERVICES: [
    { label: "Advertising Agencies", value: "ADVERTISING_AGENCIES" },
    { label: "Design Services", value: "DESIGN_SERVICES" },
    { label: "Public Relations Agencies", value: "PUBLIC_RELATIONS_AGENCIES" },
  ],
  REAL_ESTATE: [
    { label: "Property Management", value: "PROPERTY_MANAGEMENT" },
    { label: "Real Estate Agencies", value: "REAL_ESTATE_AGENCIES" },
  ],
  RESTAURANTS_AND_FOOD_SERVICE: [
    { label: "Bars & Nightclubs", value: "BARS_AND_NIGHTCLUBS" },
    {
      label: "Catering & Food Service Contractors",
      value: "CATERING_AND_FOOD_SERVICE_CONTRACTORS",
    },
    { label: "Restaurants & Cafes", value: "RESTAURANTS_AND_CAFES" },
  ],
  RETAIL_AND_WHOLESALE: [
    { label: "Auctions & Galleries", value: "AUCTION_AND_GALLERIES" },
    {
      label: "Automotive Parts & Accessories Stores",
      value: "AUTOMOTIVE_PARTS_AND_ACCESSORIES_STORES",
    },
    {
      label: "Beauty & Personal Accessories Stores",
      value: "BEAUTY_AND_PERSONAL_ACCESSORIES_STORES",
    },
    {
      label: "Consumer Electronics & Appliances Stores",
      value: "CONSUMER_ELECTRONICES_AND_APPLIANCES_STORES",
    },
    { label: "Convenience Stores", value: "CONVENIENCE_STORES" },
    {
      label: "Department, Clothing & Shoe Stores",
      value: "DEPARTMENT_CLOTHING_AND_SHOE_STORES",
    },
    { label: "Drug & Health Stores", value: "DRUG_AND_HEALTH_STORES" },
    { label: "E-commerce", value: "E_COMMERCE" },
    { label: "Food & Beverage Stores", value: "FOOD_AND_BEVERAGE_STORES" },
    {
      label: "General Merchandise & Superstores",
      value: "GENERAL_MERCHANDISE_AND_SUPERSTORES",
    },
    {
      label: "Gift, Novelty & Souvenir Stores",
      value: "GIFT_NOVELTY_AND_SOUVENIR_STORES",
    },
    { label: "Grocery Stores", value: "GROCERY_STORES" },
    {
      label: "Home Furniture & Housewares Stores",
      value: "HOME_FURNITURE_AND_HOUSEWARES_STORES",
    },
    { label: "Home Improvement Stores", value: "HOME_IMPROVEMENT_STORES" },
    { label: "Luxury Goods Retail", value: "LUXURY_GOODS_RETAIL" },
    {
      label: "Media & Entertainment Stores",
      value: "MEDIA_AND_ENTERTAINMENT_STORES",
    },
    {
      label: "Office Supply & Copy Stores",
      value: "OFFICE_SUPPLY_AND_COPY_STORES",
    },
    {
      label: "Pet & Pet Supplies Stores",
      value: "PET_AND_PET_SUPPLIES_STORES",
    },
    { label: "Specialty Stores", value: "SPECIALTY_STORES" },
    { label: "Sporting Goods Stores", value: "SPORTING_GOODS_STORES" },
    { label: "Toy & Hobby Stores", value: "TOY_AND_HOBBY_STORES" },
    { label: "Wholesale", value: "WHOLESALE" },
  ],
  SOCIAL_MEDIA_AND_NETWORKING: [
    { label: "Online Communities", value: "ONLINE_COMMUNITIES" },
    { label: "Professional Networking", value: "PROFESSIONAL_NETWORKING" },
    { label: "Social Media Platforms", value: "SOCIAL_MEDIA_PLATFORMS" },
  ],
  TELECOMMUNICATIONS: [
    { label: "Telecommunications", value: "TELECOMMUNICATIONS" },
  ],
  TRANSPORTATION_AND_LOGISTICS: [
    {
      label: "Airlines, Airports & Air Transportation",
      value: "AIRLINES_AIRPORTS_AND_AIR_TRANSPORTATION",
    },
    { label: "Car & Truck Rental", value: "CAR_AND_TRUCK_RENTAL" },
    { label: "Marine Transportation", value: "MARINE_TRANSPORTATION" },
    { label: "Parking & Valet", value: "PARKING_AND_VALET" },
    { label: "Rail Transportation", value: "RAIL_TRANSPORTATION" },
    { label: "Shipping & Trucking", value: "SHIPPING_AND_TRUCKING" },
    { label: "Taxi & Car Services", value: "TAXI_CAR_SERVICES" },
    {
      label: "Transportation Management",
      value: "TRANSPORTATION_MANAGEMENT",
    },
  ],
};

export default function getIndustryAndSubIndustry() {
  const optionGrp: optionGrp[] = [];
  industries.forEach(industry => {
    optionGrp.push({
      label: industry.label,
      value: industry.value,
      options: subIndustries[industry.value as industryValues],
    });
  });
  return optionGrp;
}
