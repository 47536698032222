import { forwardRef } from "react";
import { Control } from "react-hook-form";
import { Checkbox } from "~/components/v2/Checkbox";
import { FormField } from "~/components/v2/Form";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

interface CheckboxFieldProps {
  control: Control<any>;
  label: string;
  name: string;
  defaultChecked?: boolean;
}

export const CheckboxField = forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxFieldProps
>(({ label, control, name }, ref) => (
  <div className="flex items-center gap-2">
    <FormField
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <Checkbox
          id={`checkbox-${name}`}
          checked={value}
          onCheckedChange={onChange}
          className="border-[#CCCCCC] text-white data-[state=checked]:border-[#000D3D] data-[state=checked]:bg-[#000D3D]"
          ref={ref}
        />
      )}
    />
    <label htmlFor={`checkbox-${name}`}>{label}</label>
  </div>
));

CheckboxField.displayName = "CheckboxField";
