import { InputHTMLAttributes, ReactNode, forwardRef } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { FormField } from "~/components/v2/Form";
import LocationAutoComplete from "~/components/v2/LocationAutoComplete";
import { PlacesReturn } from "~/queries/fetchPlacesAutoComplete";
import Dialog from "../Dialog";

interface LocationFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelHelp?: string;
  error?: string;
  startAdornment?: ReactNode;
  types?: string[];
  setValue: UseFormSetValue<any>;
  control: Control<any>;
  name: string;
  isCityName?: boolean;
  placeholder?: string;
}

export const LocationField = forwardRef<HTMLButtonElement, LocationFieldProps>(
  (
    {
      label,
      labelHelp,
      error,
      startAdornment,
      required,
      types = ["(cities)"],
      setValue,
      name,
      control,
      disabled,
      isCityName = false,
      placeholder,
    },
    ref,
  ) => {
    let definitivePlaceholder = isCityName ? "ex: Chicago, IL" : "ex: 00000-000";
    if (placeholder) {
      definitivePlaceholder = placeholder;
    }
    return (
      <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <div className="flex w-full flex-col gap-1">
            {label && (
              <span className="text-sm font-semibold flex gap-1">
                {label}
                {required && "*"}
                {labelHelp && (
                  <Dialog
                    infoDialog
                    trigger={<i className="mi-circle-information"></i>}
                    title=""
                    description={
                      <span className="flex flex-col gap-4 text-center">
                        <p className="text-xs">{labelHelp}</p>
                      </span>
                    }
                  />
                )}
              </span>
            )}
            <div className="relative w-full">
              <LocationAutoComplete
                placeholder={definitivePlaceholder}
                setValue={value => {
                  setValue(name!, value, { shouldValidate: true });
                }}
                value={`${field.value || ""}`}
                name={field.name}
                disabled={disabled}
                className={`h-[2.625rem] py-2 ${
                  field.value
                    ? "border-[#595959]"
                    : "border-[#ccc] text-[#999999]"
                } ${
                  error &&
                  "border-error bg-[#FFF5F5] text-error placeholder-shown:border-error"
                }`}
                types={types}
                ref={ref}
                onBlur={field.onBlur}
                testId="location-field"
              />
              <span
                className={`absolute left-0 top-0 flex h-full items-center px-3 peer-placeholder-shown:text-[#999] ${
                  error && "text-error"
                }`}
              >
                {startAdornment}
              </span>
            </div>
            {error && (
              <span className="flex items-center gap-1 text-xs text-error">
                <i className="mi-circle-error"></i>
                {error}
              </span>
            )}
          </div>
        )}
      />
    );
  },
);

LocationField.displayName = "LocationField";
