import { forwardRef, useRef } from "react";
import { Control } from "react-hook-form";
import { FormField } from "~/components/v2/Form";
import { RadioGroup, RadioGroupItem } from "~/components/v2/RadioGroup";

export interface RadioFieldProps {
  control: Control<any>;
  label: string;
  name: string;
  placeholder?: string;
  options: { label: string; value: string }[];
  required?: boolean;
  error?: string;
  labelClassName?: string;
  optionLabelClassName?: string;
}

export const RadioField = forwardRef<HTMLInputElement, RadioFieldProps>(
  ({ label, control, name, options, required, error, labelClassName, optionLabelClassName }, ref) => {
    const firstRef = useRef<HTMLButtonElement>(null);

    const handleFocus = () => {
      firstRef.current?.focus?.();
    };

    return (
      <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <div className="flex flex-col gap-1">
            {label && (
              <span className={"text-sm font-semibold" && labelClassName}>
                {label}
                {required && "*"}
              </span>
            )}
            <input
              type="text"
              ref={ref}
              defaultValue={field.value ?? ""}
              onChange={() => { }}
              onFocus={handleFocus}
              className="h-0 w-0 overflow-clip"
            />
            <RadioGroup onValueChange={field.onChange} value={field.value}>
              {options.map(({ value, label }, index) => (
                <div className="flex items-center gap-2 space-x-2" key={value}>
                  <RadioGroupItem
                    ref={index === 0 ? firstRef : undefined}
                    value={value}
                    id={`option-${name}-${value}`}
                    className={error && "border-error"}
                    onBlur={field.onBlur}
                    defaultValue={field.value}
                  />
                  <label className={optionLabelClassName || ''} htmlFor={`option-${name}-${value}`}>{label}</label>
                </div>
              ))}
            </RadioGroup>

            {error && (
              <span className="flex items-center gap-1 text-xs text-error">
                <i className="mi-circle-error"></i>
                {error}
              </span>
            )}
          </div>
        )}
      />
    );
  },
);

RadioField.displayName = "RadioField";
